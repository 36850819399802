exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cedulaspace-js": () => import("./../../../src/pages/cedulaspace.js" /* webpackChunkName: "component---src-pages-cedulaspace-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-space-js": () => import("./../../../src/pages/space.js" /* webpackChunkName: "component---src-pages-space-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-all-pages-js": () => import("./../../../src/templates/allPages.js" /* webpackChunkName: "component---src-templates-all-pages-js" */),
  "component---src-templates-cedulas-js": () => import("./../../../src/templates/cedulas.js" /* webpackChunkName: "component---src-templates-cedulas-js" */)
}

